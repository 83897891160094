/*=========================================================================================
File Name: WelcomeArea.vue
Description: Welcome Area Component.
----------------------------------------------------------------------------------------
Item Name: Nuxa Vue - Product Landing Page
Version: 1.0.0
Author: Deveable

Copyright 2021
==========================================================================================*/

<template>
  <!-- Welcome Area Start -->
  <section class="nuxa-welcome-area fix" id="elemHome">
    <!-- Shape Start -->
    <b-img-lazy alt="img" class="welcome-shape" fluid v-bind:src="require('../assets/img/hero_bg.png')"/>
    <!-- Shape End -->
    <b-container>
      <b-row class="align-items-center">
        <b-col cols="12" md="6">
          <div class="welcome-text" data-aos="fade-right">
            <h2>Smart Digital <span>Watch</span> For Luxurious Life</h2>
            <p>
              Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim
              pellentesque.
            </p>
            <a class="nuxa-btn" href="#">Order Now</a>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="welcome-image" data-aos="fade-left">
            <b-img-lazy alt="img" class="moving1" fluid v-bind:src="require('../assets/img/hero_img.png')"/>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Welcome Area End -->
</template>

<script>
export default {
  name: "WelcomeArea"
}
</script>

<style scoped>

</style>
