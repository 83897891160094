<template>
  <!-- Footer Area Start -->
  <footer class="nuxa-footer-area">
    <b-container>
      <b-row>
        <b-col lg="12">
          <div class="footer-box">
            <div class="footer-logo">
              <a href="index.html"><img alt="footer logo" src="../assets/img/footer-logo.png"/></a>
            </div>
            <div class="footer-copyright">
              <p>Copyright &copy; Deveable 2021.</p>
            </div>
            <div class="footer-social">
              <ul>
                <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                <li><a href="#"><i class="fa fa-youtube"></i></a></li>
              </ul>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </footer>
  <!-- Footer Area End -->
</template>

<script>
export default {
  name: "AppFooter"
}
</script>

<style scoped>

</style>
