/*=========================================================================================
File Name: VideoArea.vue
Description: Video Area Component.
----------------------------------------------------------------------------------------
Item Name: Nuxa Vue - Product Landing Page
Version: 1.0.0
Author: Deveable

Copyright 2021
==========================================================================================*/

<template>
  <!-- Video Area Start -->
  <section class="nuxa-video-area section_100">
    <b-container>
      <b-row>
        <b-col lg="12">
          <div class="video-box" data-aos="fade-down">
            <div class="video-inn">
              <a class="popup-youtube" href="https://www.youtube.com/watch?v=k-R6AFn9-ek">
                <i class="flaticon-play"></i>
              </a>
              <div class="site-heading">
                <h2><span>Best Product</span> For You.<br> Check The <span>Demo Video</span>.</h2>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.
                Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.
                dolor sit amet, consectetuer adipiscing elit.
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Video Area End -->
</template>

<script>

export default {
  name: "VideoArea",
}
</script>

<style scoped>

</style>
