/*=========================================================================================
File Name: ProductArea.vue
Description: Product Area Component.
----------------------------------------------------------------------------------------
Item Name: Nuxa Vue - Product Landing Page
Version: 1.0.0
Author: Deveable

Copyright 2021
==========================================================================================*/

<template>
  <!-- Product Area Start -->
  <section class="nuxa-product-area section_100" id="elemProduct">
    <b-container>
      <b-row>
        <b-col lg="12">
          <div class="site-heading" data-aos="fade-down">
            <h2>Check <span>the best</span> collection<br> of our <span>products</span></h2>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4" v-for="(product, key) in products" :key="key">
          <div class="product_item" data-aos="fade-down">
            <div class="product_img">
              <b-img-lazy fluid v-bind:src="product.img" alt="img"/>
            </div>
            <div class="product_text">
              <h3>{{product.title}}</h3>
              <ul>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
              </ul>
              <div class="pro-meta">
                <div class="price">
                  <h2>${{product.price}}</h2>
                </div>
                <div class="pro-action">
                  <a class="nuxa-btn" href="#">Order Now</a>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Product Area End -->
</template>

<script>
export default {
  name: "ProductArea",
  data() {
    return {
      products: [
        {
          title: 'Black Watch Pro',
          img: require('../assets/img/product_1.png'),
          price: '40'
        },
        {
          title: 'White Watch Max',
          img: require('../assets/img/product_2.png'),
          price: '47'
        },
        {
          title: 'Blue Watch Pro',
          img: require('../assets/img/product_3.png'),
          price: '54'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
