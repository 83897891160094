/*=========================================================================================
File Name: AboutArea.vue
Description: About Area Component.
----------------------------------------------------------------------------------------
Item Name: Nuxa Vue - Product Landing Page
Version: 1.0.0
Author: Deveable

Copyright 2021
==========================================================================================*/

<template>
  <!-- About Area Start -->
  <section class="nuxa-about-area section_100" id="elemAbout">
    <b-container>
      <b-row class="align-items-center">
        <b-col lg="6">
          <div class="about-left">
            <b-img-lazy fluid v-bind:src="require('../assets/img/about.png')" alt="about" class=" moving2"/>
          </div>
        </b-col>
        <b-col lg="6">
          <div class="about-right" data-aos="fade-left">
            <div class="site-heading">
              <h2><span>Go for</span> a Run. and leave your phone <span>at home.</span></h2>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.Donec nec justo eget felis facilisis fermentum.</p>
            <b-list-group flush>
              <b-list-group-item class="border-0 p-0"><i class="fa fa-check"></i> Camera Resulation 24 Mega Pixel</b-list-group-item>
              <b-list-group-item class="border-0 p-0"><i class="fa fa-check"></i> Real-time Heart Rate Monitor</b-list-group-item>
              <b-list-group-item class="border-0 p-0"><i class="fa fa-check"></i> Blood-oxygen Level Monitor</b-list-group-item>
              <b-list-group-item class="border-0 p-0"><i class="fa fa-check"></i> Intelligent Activity Tracker</b-list-group-item>
              <b-list-group-item class="border-0 p-0"><i class="fa fa-check"></i> Music and Camera Controls</b-list-group-item>
            </b-list-group>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- About Area End -->
</template>

<script>
export default {
  name: "AboutArea"
}
</script>

<style scoped>

</style>
